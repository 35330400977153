import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Home from './components/Home';
import Login from './components/auth/Login';
import SignUp from './components/auth/SignUp'
import ChangePassword from './components/auth/ChangePassword'
import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { AuthContext } from "./context/auth";
import { MessageContext } from "./context/message";
import { ChatContext } from './context/chat'
import { FieldContext } from './context/context'
import PrivateRoute from './routes/PrivateRoute';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const primary = process.env.NODE_ENV === 'production' ? '#fc6c05' : '#1EFF00'

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: primary,
    },
    secondary: {
      main: '#FFFFFF',
      secondary: '#545454',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  '@global': {
    /* width */
    '::-webkit-scrollbar': {
      height: '8px',
      width: '8px'
    },
    /* Handle */
    '::-webkit-scrollbar-thumb' :{
      background: '#111',
      'borderRadius':'25px',
      border: '2px solid white'

    },
    /* Handle on hover */
    '::-webkit-scrollbar-thumb:hover' :{
      background: '#555'
    },
  },
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function App() {
  const classes = useStyles();
  const [message, setMessage] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem('token'));
  const [username, setUsername] = useState(sessionStorage.getItem('username'));
  const [userCategory, setUserCategory] = useState(sessionStorage.getItem('category'));
  const [user_id, setUser_id] = useState(sessionStorage.getItem('user_id'))
  const [team_id, setTeam_id] = useState(sessionStorage.getItem('team_id'))
  const [tutorialStep, setTutorialStep] = useState(Number(sessionStorage.getItem('tutorialStep')))
  const [dataSub, setDataSub] = useState(Number(sessionStorage.getItem('dataSub')))
  const [tankwatch_api, setTankwatch] = useState(sessionStorage.getItem('tankwatch'))
  const [openConversations, setOpenConversations] = useState([])
  const [currentChat, setCurrentChat] = useState({})
  const [chatroom, setChatroom] = useState(false)
  let anchorRef = useRef([])
  const [fetchConversations, setFetchConversations] = useState(false)
  const [expired, setExpired] = useState(false)
  //field context
  const [regions, setRegions] = useState([])
  const [product_categories, setProduct_categories] = useState([])
  const [products, setProducts] = useState([])
  const [fetchFields, setFetchFields] = useState(false)

  const auth = (response) => {
    if (response.status === 401 && isAuthenticated) {
      setExpired(true)
    }
    if (response.status === 413){
      return {'message': {'type': 'error', 'text': 'Unable to attach files. Upload size too large.'}}
    }
    return response.json()
  };

  useEffect(() => {
    fetch('/api/auth', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then((data) => {
      if (data.isAuthenticated) {
        setIsAuthenticated(data.isAuthenticated);
        setUsername(data.username);
        setUserCategory(data.category);
        setUser_id(data.user_id);
        setTeam_id(data.team_id);
        setDataSub(data.data_sub)
        setTankwatch(data.tankwatch_api)
        if (!tutorialStep && tutorialStep !== 0)
        {
            setTutorialStep(data.tutorialStep)
        }
        setMessage(data.message);
        setExpired(false);
      } else {
        setIsAuthenticated(data.isAuthenticated);
        setMessage(data.message);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    fetch('/api/context', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then(response => auth(response))
    .then((response) => {
      setRegions(response.regions)
      setProduct_categories(response.product_categories)
      setProducts(response.products)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchFields])

  useEffect(() => {
    if (tutorialStep || tutorialStep === 0)
      sessionStorage.setItem('tutorialStep', tutorialStep)
  }, [tutorialStep])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        expired,
        setExpired,
        auth,
        username,
        setUsername,
        userCategory,
        setUserCategory,
        user_id,
        setUser_id,
        team_id,
        setTeam_id,
        tutorialStep,
        setTutorialStep,
        dataSub,
        setDataSub,
        tankwatch_api,
        setTankwatch
      }}>
      <FieldContext.Provider
        value = {{
          regions,
          product_categories,
          products,
          fetchFields,
          setFetchFields
      }}>
        <MessageContext.Provider
          value={{
            message,
            setMessage
          }}>
          <ChatContext.Provider
            value = {{
              openConversations,
              setOpenConversations,
              currentChat,
              setCurrentChat,
              chatroom,
              setChatroom,
              fetchConversations,
              setFetchConversations,
              anchorRef
            }}>
            <ThemeProvider theme={theme}>
              <Router>

                <Switch>
                  <Route path='/login' component={Login} />
                  <Route path = '/changePassword/:token' component={ChangePassword}/>
                  <Route path = {`/sign_up`} component={SignUp}/>
                  <PrivateRoute path='/' component={Home} />
                </Switch>

                <Snackbar autoHideDuration={6000} className={classes.root} open={Boolean(message)} onClose={handleClose} style = {tutorialStep !== 0 ? {bottom:85} :null}>
                  {message && <Alert onClose={handleClose} severity={message.type}>{message.text}</Alert>}
                </Snackbar>
              </Router>
            </ThemeProvider>
          </ChatContext.Provider>
        </MessageContext.Provider>
      </FieldContext.Provider>
    </AuthContext.Provider>
  );

}

export default App;
