import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import IconButton from '@material-ui/core/IconButton'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import HTMLTooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import InfoDialog from '../display/InfoDialog'

const inquiryTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#3f51b5'
    }
  }
})

function MapComponent ({id, volume, price, product, value}) {
  const [info, setInfo] = useState(false)

  const handleCloseInfo = () => {
    setInfo(false)
  }


  return (
    <div>
      <InfoDialog
        open = {info}
        onClose = {handleCloseInfo}
        type = 'listing_info'
        id = {id}
      />
      <HTMLTooltip
        title = {
              <Typography>
                Price : {price} ($/BBL)<br/>
                Product : {product} <br/>
                Volume : {volume} (MBBL)<br/>
              </Typography>
        }
        onClick = {() => {setInfo(true)}}>
        <IconButton
          size = 'small'
        >
            <LocationOnIcon color = 'primary'/>
        </IconButton>
      </HTMLTooltip>
    </div>
  )
}


function InquiryMapComponent ({id, min_volume, max_volume, price, product, value}) {
  const [info, setInfo] = useState(false)
  const handleCloseInfo = () => {
    setInfo(false)
  }

  return (
    <div>
      <InfoDialog
        open = {info}
        onClose = {handleCloseInfo}
        type = 'inquiry_info'
        id = {id}
      />
      <HTMLTooltip
        title = {
              <Typography>
                Price : {price} ($/BBL)<br/>
                Product : {product} <br/>
                Volume Range : {min_volume} - {max_volume} (MBBL)<br/>
              </Typography>

        }
        onClick = {() => setInfo(true)}>
        <IconButton
          size = 'small'
        >
          <ThemeProvider theme = {inquiryTheme}>
            <LocationOnIcon color = 'primary'/>
          </ThemeProvider>
        </IconButton>
      </HTMLTooltip>
    </div>

  )
}

export default function ListingMap({listings, inquirys, showInquirys, showListings}){
  const defaultMapOptions = {
    fullscreenControl: false,
    styles: [
      { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
      { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: 'poi',
        stylers: [{visibility: 'off'}]
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [{ color: "#263c3f" }],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [{ color: "#6b9a76" }],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#38414e" }],
      },
      {
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [{ color: "#212a37" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#9ca5b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [{ color: "#746855" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ color: "#1f2835" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [{ color: "#f3d19c" }],
      },
      {
        featureType: "transit",
        elementType: "geometry",
        stylers: [{ color: "#2f3948" }],
      },
      {
        featureType: "transit.station",
        elementType: "labels.text.fill",
        stylers: [{ color: "#d59563" }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{visibility: 'off'}]
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#17263c" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#515c6d" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#17263c" }],
      },
    ]
  };

  return(
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{key:'AIzaSyBTp8nnXh_LBIFetGatTUUEzjsT58DCg9A'}}
        center={{lat:37,lng:-95}}
        zoom={4}
        hoverDistance={0.1}
        options={defaultMapOptions}
      >
        {showListings && listings.map((listing) => (
          <MapComponent
            key = {listing['listing_id']}
            lat = {parseFloat(listing['newLatitude'])}
            lng = {parseFloat(listing['newLongitude'])}
            id = {listing['listing_id']}
            value = {listing['listing_id']}
            volume = {listing['working_capacity']}
            price = {listing['ask_price']}
            product = {listing['product_category']}
          />
        ))
        }
        {showInquirys && inquirys.map((inquiry) => (inquiry['latitude'] &&
          <InquiryMapComponent
            key = {inquiry['inquiry_id']}
            lat = {parseFloat(inquiry['newLatitude'])}
            lng = {parseFloat(inquiry['newLongitude'])}
            id = {inquiry['inquiry_id']}
            value = {inquiry['inquiry_id']}
            min_volume = {inquiry['min_volume']}
            max_volume = {inquiry['max_volume']}
            price = {inquiry['bid_price']}
            product = {inquiry['product']}
          />
        ))
        }
      </GoogleMapReact>
    </div>
  )
}
