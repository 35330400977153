import React, {useState, useEffect} from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useMessage } from "../../context/message";
import { useAuth } from "../../context/auth";
import Preference from '../preference/Preference';
import AlertDialog from '../inputs/AlertDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
    },
  },
  heading: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2),
  },
  switch: {
    marginTop: theme.spacing(3)
  },
  profile: {
    marginBottom: theme.spacing(3)
  }
}));

export default function Profile() {
  const classes = useStyles();
  const {setMessage} = useMessage();
  const { auth } = useAuth();
  const [updateProfile, setUpdateProfile] = useState(false)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [company, setCompany] = useState('');
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [email, setEmail] = useState('');
  const [cell_phone, setCell_Phone] = useState('');
  const [office_phone, setOffice_Phone] = useState('');
  const [subscribed, setSubscribed] = useState(true);
  const [fetchData, setFetchData] = useState(false);

  useEffect(() => {
    fetch('/api/user/profile', {
      method: 'GET',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      setUsername(response.data.username);
      setCompany(response.data.company);
      setFirst_name(response.data.first_name);
      setLast_name(response.data.last_name);
      setEmail(response.data.email);
      setOffice_Phone(response.data.office_phone);
      setCell_Phone(response.data.cell_phone);
      const temp = Boolean(response.data.subscribed);
      setSubscribed(temp);
      setPassword('');
      setNewPassword('');
      setConfirmPassword('');
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fetchData])

  const handleSubmit = () => {
    setUpdateProfile(false)
    fetch('/api/user/profile', {
      method: 'PUT',
      headers: {
        'x-access-token': sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          username: username,
          company: company,
          first_name: first_name,
          last_name: last_name,
          email: email,
          office_phone: office_phone,
          cell_phone: cell_phone,
          subscribed: subscribed,
          password: password,
          newPassword: newPassword,
          confirmPassword: confirmPassword
        }
      ),
    })
    .then((response) => auth(response))
    .then((response) => {
      setMessage(response.message);
      if (response.message.type==='success') {setFetchData(!fetchData);}
    })
  }

  return (
    <Grid container>
      <AlertDialog
        open = {updateProfile}
        setOpen = {setUpdateProfile}
        title = 'Update Profile'
        content = 'Are you sure you want to update profile?'
        submitText = 'Update'
        submit = {handleSubmit}
      />
      <Grid item xs={12} className={classes.profile}>
        <Preference/>
      </Grid>
      <Grid item xs={12} className={classes.profile}>
        <Paper className={classes.root}>
          <Grid container>
            <Typography color='primary' className={classes.heading}>User Info:</Typography>
          </Grid>
          <TextField
            label='Username'
            variant='outlined'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label='Email'
            variant='outlined'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label='Company'
            variant='outlined'
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <TextField
            label='First Name'
            variant='outlined'
            value={first_name}
            onChange={(e) => setFirst_name(e.target.value)}
          />
          <TextField
            label='Last Name'
            variant='outlined'
            value={last_name}
            onChange={(e) => setLast_name(e.target.value)}
          />
          <TextField
            label='Office Phone'
            variant='outlined'
            value={office_phone}
            onChange={(e) => setOffice_Phone(e.target.value)}
          />
          <TextField
            label='Cell Phone'
            variant='outlined'
            value={cell_phone}
            onChange={(e) => setCell_Phone(e.target.value)}
          />
          <FormControlLabel
            className={classes.switch}
            control={<Switch color='primary' checked={subscribed} onChange={() => setSubscribed(!subscribed)} />}
            label={subscribed ? 'Receive Email Notifications' : 'Block Email Notifications'}
          />
          <Grid container>
            <Typography color='primary' className={classes.heading}>Change Password:</Typography>
          </Grid>
          <Grid container>
            <TextField
              label='Old Password'
              variant='outlined'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              label='New Password'
              variant='outlined'
              type='password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              label='Confirm Password'
              variant='outlined'
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Grid>
          <Grid container>
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={() => setUpdateProfile(true)}
              >
                Update
              </Button>
              <Button
                className={classes.button}
                variant='contained'
                color='primary'
                onClick={() => setFetchData(!fetchData)}
                >
                  Cancel
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )
  }
